<template>
  <main>
    <div class="contact-wrapper">
      <div class="contact-title">
        <h2 class="contact-title-inner">Contact</h2>
      </div>
      <div class="contact-form">
        <p class="contact-form-text">
          下記のQRコード、または友達追加ボタンよりお問い合わせください。
        </p>
        <div class="contact-form-inner">
          <img
            class="contact-form-qr"
            src="https://qr-official.line.me/gs/M_787eitsz_BW.png?oat_content=qr"
          />
          <a href="https://lin.ee/dLIOyeA" class="contact-form-button-wrapper"
            ><img
              class="contact-form-button"
              src="https://scdn.line-apps.com/n/line_add_friends/btn/ja.png"
              alt="友だち追加"
              height="36"
              border="0"
          /></a>
        </div>
        <!-- <form action="https://docs.google.com/forms/u/0/d/e/1FAIpQLSetTPGDLRHts22Xec-fGTFQezMz5QlCBg1yjSOqderHEgA3zw/formResponse" method="POST" id="mG61Hd" target="hidden_iframe" onsubmit="submitted=true;">
          <div class="contact-form-list">
            <ul class="contact-form-list-inner">
              <li class="contact-form-item" v-for="item in items" :key="item">
                <div class="contact-form-item-title">
                  <p class="contact-form-item-title-inner">
                    {{item.title}}
                  </p>
                </div>
                <div class="contact-form-item-input">
                  <input :id="item.id" class="contact-form-item-input-inner" required :name="item.name" v-model="$data[item.data]" />
                </div>
              </li>
              <li class="contact-form-item">
                <div class="contact-form-item-title">
                  <p class="contact-form-item-title-inner">
                    お問い合わせ内容
                  </p>
                </div>
                <div class="contact-form-item-input">
                  <textarea rows="5" id="FormDetail" class="contact-form-item-input-inner" required name="entry.1717289309" v-model="formText" />
                </div>
              </li>
            </ul>
          </div>
          <div class="contact-form-button">
            <button class="contact-form-button-inner" type="submit" @:click="openModal">送信する</button>
          </div>
        </form> -->
      </div>
    </div>
    <!-- <iframe
      name="hidden_iframe"
      id="hidden_iframe"
      style="display: none"
    ></iframe>
    <Transition name="Modal">
      <div id="overlay" class="contact-overlay" v-show="showContent">
        <div class="contact-overlay-main">
          <div class="contact-overlay-image">
            <img
              src="@/assets/image/logo/logo_alku_symbol_white.png"
              class="contact-overlay-image-"
            />
          </div>
          <div class="contact-overlay-text">
            <p class="contact-overlay-text-inner">
              お問い合わせありがとうございます。<br />
              後日、担当よりご連絡させていただきます。
            </p>
          </div>
          <div class="contact-overlay-back">
            <button class="contact-overlay-back-button" @:click="closeModal">
              戻る
            </button>
          </div>
        </div>
      </div>
    </Transition> -->
  </main>
</template>

<script>
export default {
  data: () => {
    return {
      showContent: false,
      items: [
        {
          title: "お名前",
          name: "entry.150615070",
          data: "formName",
        },
        {
          title: "電話番号",
          name: "entry.1282220521",
          data: "formTel",
        },
        {
          title: "メールアドレス",
          name: "entry.193776473",
          data: "formAddress",
        },
      ],
    };
  },
  methods: {
    openModal: function () {
      if ((this.formName, this.formTel, this.formAddress, this.formText)) {
        this.showContent = true;
      }
    },
    closeModal: function () {
      (this.formName = ""),
        (this.formTel = ""),
        (this.formAddress = ""),
        (this.formText = ""),
        (this.showContent = false);
    },
  },
};
</script>

<style lang="scss" scoped>
@import "../assets/css/scss/style.scss";
@import url("https://fonts.googleapis.com/css2?family=Yantramanav&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Yantramanav&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Kiwi+Maru&family=Yantramanav&display=swap");

main {
  position: relative;
  // height: 100vh;
  padding: 50px 0;
  background-color: #ffffff;
}
.contact {
  &-wrapper {
    @include fontFamily();
    margin: auto;
    @include mq(lg) {
      width: 60%;
    }
    @include mq(pc) {
      width: 80%;
    }
    @include mq(sp) {
      width: 90%;
    }
  }
  &-title {
    @include jText(n);
    @include textColor(other);
    &-inner {
      text-align: center;
      font-size: 36px;
    }
  }

  &-form {
    margin-top: 50px;
    text-align: center;
    &-item {
      @include mq(lg) {
        display: flex;
      }
      @include mq(pc) {
        display: flex;
      }
      @include mq(sp) {
        width: 80%;
        margin: auto;
      }
      &:nth-of-type(n + 2) {
        margin-top: 40px;
      }
      &-title {
        display: table;
        @include mq(lg) {
          width: 30%;
        }
        @include mq(pc) {
          width: 40%;
        }
        &-inner {
          @include textColor(other);
          text-align: center;
          font-size: 26px;
          vertical-align: middle;
          display: table-cell;
        }
      }
      &-input {
        width: 70%;
        @include mq(lg) {
          width: 70%;
        }
        @include mq(pc) {
          width: 60%;
        }
        @include mq(sp) {
          width: 100%;
        }
        &-inner {
          font-size: 26px;
          width: 100%;
          border: 1px solid #3b4043;
          border-radius: 5px;
          background-color: #ffffff;
          @include mq(sp) {
            margin-top: 10px;
          }
        }
      }
    }
    &-text {
      font-size: 26px;
    }
    &-inner {
      margin-top: 30px;
    }
    &-qr,
    &-button {
      margin: auto;
    }

    &-button {
      margin-top: 40px;
      text-align: center;
      &-inner {
        @include jText(n);
        display: flex;
        justify-content: center;
        align-items: center;
        margin: 0 auto;
        padding: 1em 2em;
        font-size: 24px;
        border: solid #7ea0bb 1px;
        background-color: #7ea0bb;
        transition: 0.3s;
        color: white;
        border-radius: 10px;
        &::after {
          content: "→";
        }
        &:hover {
          text-decoration: none;
          background-color: #ffffff;
          @include textColor(other);
        }
      }
    }
  }

  &-overlay {
    z-index: 1;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5);
    display: flex;
    align-items: center;
    justify-content: center;
    @include fontFamily();
    &-main {
      width: 80%;
      // height:80%;
      border-radius: 10px;
      background-color: #7ea0bb;
      padding: 30px 0;
    }
    &-image {
      width: 50vh;
      margin: auto;
      @include mq(lg) {
        width: 50vh;
      }
      @include mq(pc) {
        width: 50vh;
      }
      @include mq(sp) {
        width: 80%;
      }
    }
    &-text {
      margin-top: 50px;
      &-inner {
        font-size: 30px;
        text-align: center;
        @include mq(lg) {
          font-size: 30px;
        }
        @include mq(pc) {
          font-size: 30px;
        }
        @include mq(sp) {
          font-size: 14px;
        }
      }
    }
    &-back {
      margin-top: 40px;
      text-align: center;
      &-button {
        display: flex;
        justify-content: center;
        align-items: center;
        margin: 0 auto;
        padding: 0.5em 2em;
        font-weight: 700;
        background-color: #ffffff;
        transition: 0.3s;
        color: rgb(43, 43, 43);
        border: solid white;
        border-radius: 10px;
        @include mq(lg) {
          font-size: 24px;
        }
        @include mq(pc) {
          font-size: 24px;
        }
        @include mq(sp) {
          font-size: 16px;
        }
        &::before {
          content: "←";
        }
        &:hover {
          text-decoration: none;
          background-color: #7ea0bb;
          color: #ffffff;
          @include jText(r);
        }
      }
    }
  }
}

.Modal-enter-active {
  transition: opacity 0.5s ease;
}
.Modal-leave-active {
  transition: opacity 0.5s ease;
}
.Modal-enter-from,
.Modal-leave-to {
  opacity: 0;
}
</style>
