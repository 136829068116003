<template>
  <mian>
    <h1>shopping</h1>
  </mian>
</template>

<script>
export default {

}
</script>

<style>

</style>