import { createRouter, createWebHistory } from 'vue-router'
import Top from '@/views/TopView/'
import Shopping from '@/views/ShoppingView/'
import Contact from '@/views/ContactView/'

const routes = [
    { path: '/', name: 'Top', component: Top },
    { path: '/shopping', name: 'Shopping', component: Shopping },
    { path: '/contact', name: 'contact', component: Contact },
]

const router = createRouter({
    history: createWebHistory(),
    base: "https://alkukukka.com/",
    routes,
})
// const router = new VueRouter({
//     mode: "history",
//     base: "https://alku.herokuapp.com/",
//     routes
// })

export default router