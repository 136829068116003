<template>
  <main>
    <div class="top-wrapper">
      <div class="top-slide-wrapper">
        <ul class="top-slide-contents">
          <li
            v-for="(slide, index) in slides"
            :key="slide.id"
            class="top-slide-contents-list"
          >
            <transition name="fade">
              <div
                :key="imgSrc(index)"
                v-show="currentSlide == index"
                class="top-slide-image-wrapper"
              >
                <img
                  :key="imgSrc(index)"
                  :src="imgSrc(index)"
                  class="top-slide-image"
                />
              </div>
            </transition>
          </li>
        </ul>
      </div>
      <div class="top-service" id="Home">
        <!-- <div class="subtitle">
          <h2 class="subtitle-text">Home</h2>
        </div> -->
        <div class="top-service-explain">
          <p class="top-service-explain-text">
            店名のAlkuアルクは<br />
            『はじまり』という<br />
            フィンランドの言葉です<br />
            <br />
            生花装飾業界を走るように<br />
            長く過ごした店主が<br />
            歩くようにゆっくりと…<br />
            皆様とのご縁が<br />
            始まりますようにと願い<br />
            小さな店の名にしました<br />
            <br />
            気軽に普段の花屋さんとして<br />
            便利にお使いいただければ嬉しいです<br />
          </p>
        </div>
        <div class="top-service-contents">
          <ul class="top-service-contents-wrapper">
            <li
              class="top-service-contents-list"
              v-for="service in services"
              :key="service.id"
            >
              <div class="top-service-contents-detail">
                <div class="top-service-contents-detail-explain">
                  <h3 class="top-service-contents-detail-explain-title">
                    {{ service.title }}
                  </h3>
                  <p class="top-service-contents-detail-explain-text">
                    {{ service.text }}
                  </p>
                </div>
                <div class="top-service-contents-detail-image">
                  <img
                    :src="service.imagePath"
                    class="top-service-contents-detail-image-content"
                  />
                </div>
              </div>
            </li>
          </ul>
        </div>
      </div>
      <!-- インフォメーション -->
      <div class="top-information" id="Information">
        <div class="subtitle">
          <h2 class="subtitle-text">Information</h2>
        </div>
        <div class="top-information-wrapper">
          <ul class="top-information-contents">
            <li
              class="top-information-contents-list"
              v-for="data in information"
              :key="data.id"
            >
              <div class="top-information-contents-list-title">
                <p class="top-information-contents-list-title-text">
                  {{ data.title }}
                </p>
              </div>
              <div class="top-information-contents-list-explain">
                <p class="top-information-contents-list-explain-text">
                  {{ data.text }}
                </p>
              </div>
            </li>
          </ul>
        </div>
      </div>
      <!-- アクセス -->
      <div class="top-access" id="Accesses">
        <div class="subtitle">
          <h2 class="subtitle-text">Access</h2>
        </div>
        <div class="top-access-contents">
          <div class="top-access-contents-explain">
            <div class="top-access-contents-explain-text">
              <div class="top-access-contents-explain-text-header">
                <p class="top-access-contents-explain-text-header-content">
                  住所
                </p>
              </div>
              <div class="top-access-contents-explain-text-inner">
                <p class="top-access-contents-explain-text-inner-content">
                  〒573-0013<br />
                  枚方市星丘3-1-46
                </p>
                <p class="top-access-contents-explain-text-inner-content">
                  ※京阪 星ヶ丘駅東出口から徒歩5分
                </p>
              </div>
            </div>
            <div class="top-access-contents-explain-image">
              <img
                src="@/assets/image/店舗画像.png"
                class="top-access-contents-explain-image-content"
              />
            </div>
          </div>
          <div class="top-access-contents-map">
            <iframe
              src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3275.9252513243828!2d135.66072395116473!3d34.807822384884155!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x60011c13e57e55f9%3A0x9cecc9ff8b3b1fd!2z44CSNTczLTAwMTMg5aSn6Ziq5bqc5p6a5pa55biC5pif5LiY77yT5LiB55uu77yR4oiS77yU77yW!5e0!3m2!1sja!2sjp!4v1673736325291!5m2!1sja!2sjp"
              class="top-access-contents-map-content"
              style="border: 0"
              allowfullscreen=""
              loading="lazy"
              referrerpolicy="no-referrer-when-downgrade"
            ></iframe>
          </div>
        </div>
      </div>
    </div>
  </main>
</template>

<script>
export default {
  data() {
    return {
      currentSlide: 0,
      slides: [
        { path: require("@/assets/image//top/トップスライド_1.jpg") },
        { path: require("@/assets/image//top/トップスライド_2.jpg") },
        { path: require("@/assets/image//top/トップスライド_3.jpg") },
        { path: require("@/assets/image//top/トップスライド_4.jpg") },
        { path: require("@/assets/image//top/トップスライド_5.jpg") },
      ],
      services: [
        {
          id: 1,
          imagePath: require("@/assets/image/サービス/花/花_4.jpg"),
          title: "花",
          text: "お花一本からお買い求めいただけます\n\n贈り物のアレンジや花束のご注文は、鮮度とご用途を考え丁寧にご用意させていただきたいので、ご予約をオススメしています\n\n胡蝶蘭や開店祝スタンド等の配達も承ります。\nお気軽にご来店、またはお電話でもご相談ください",
          linkPath: "",
        },
        {
          id: 2,
          imagePath: require("@/assets/image/サービス/緑/緑_1.jpg"),
          title: "緑",
          text: "ご自宅用や、開店祝い・各種贈り物に観葉植物を選ばれる方が増えています\n店内には、愛情もって手入れした植物と、おしゃれな鉢などもご用意しています\nどうぞ植物に癒されに来てみてください",
          linkPath: "",
        },
        {
          id: 3,
          imagePath: require("@/assets/image/サービス/雑貨/雑貨_1.jpg"),
          title: "雑貨",
          text: "北欧メーカーの食器やテキスタイル雑貨。\n花瓶や籠・園芸資材など可愛いアイテムを少しずつ置いています。",
          linkPath: "",
        },
      ],
      information: [
        {
          id: 1,
          title: "OPEN",
          text: "10:00 ～ 18:00",
        },
        {
          id: 2,
          title: "CLOSE",
          text: "水曜日・木曜日",
        },
        {
          id: 3,
          title: "電話番号",
          text: "050-1433-9052",
        },
        {
          id: 4,
          title: "駐車場",
          text: "有りません\n近隣コインパーキングご利用いただき、駐車証明書をお持ちください\n◎3,000円以上のお買い物で駐車料金お値引きをさせていただきます",
        },
      ],
    };
  },
  mounted() {
    setInterval(() => {
      this.currentSlide =
        this.currentSlide < this.slides.length - 1 ? this.currentSlide + 1 : 0;
    }, 5000);
  },
  methods: {
    imgSrc(index) {
      return this.slides[index].path;
    },
  },
};
</script>

<style lang="scss">
@import "../assets/css/scss/style.scss";
@import url("https://fonts.googleapis.com/css2?family=Yantramanav&display=swap");

.top-wrapper {
  @include fontFamily();
  padding: 30px;
  @include jText(n);
  background-color: #ffffff;
  max-width: $maxWidth;
  min-width: $minWidth;
  margin: auto;
}
.top-slide {
  &-wrapper {
    // height: 84vh;
    position: relative;
    @include mq(lg) {
      height: 84vh;
      max-width: 1000px;
      margin: auto;
    }
    @include mq(pc) {
      height: 84vh;
    }
    @include mq(sp) {
      height: 50vh;
    }
  }
  &-contents-list {
    position: absolute;
    top: 0;
    left: 0;
    overflow: hidden;
    width: 100%;
    @include mq(lg) {
      height: 84vh;
    }
    @include mq(pc) {
      height: 84vh;
    }
    @include mq(sp) {
      height: 50vh;
    }
  }
  &-image {
    object-fit: cover;
    width: 100%;
    height: 100%;
    overflow: hidden;
    &-wrapper {
      width: 100%;
      height: 100%;
      aspect-ratio: 4/2;
    }
  }
}
.fade-enter-from,
.fade-leave-to {
  opacity: 0;
}
.fade-enter-to,
.fade-leave-from {
  opacity: 1;
}
.fade-enter-active,
.fade-leave-active {
  transition: opacity 3s ease-out;
}

// 共通
.subtitle {
  @include jText(r);
  @include textColor(other);
  &-text {
    text-align: center;
    @include mq(lg) {
      font-size: 36px;
    }
    @include mq(pc) {
      font-size: 30px;
    }
    @include mq(sp) {
      font-size: 24px;
    }
  }
}

/* サービス */
.top-service {
  @include mq(lg) {
    margin-top: 100px;
  }
  @include mq(pc) {
    margin-top: 100px;
  }
  @include mq(sp) {
    margin-top: 50px;
  }
  margin-top: 100px;
  &-explain {
    margin-top: 30px;
    &-text {
      text-align: center;
      @include textColor(other);
      @include mq(lg) {
        font-size: 20px;
      }
      @include mq(pc) {
        font-size: 16px;
      }
      @include mq(sp) {
        font-size: 16px;
      }
    }
  }
  &-wrapper {
    margin-top: 20px;
    @include mq(pc) {
      display: flex;
    }
    @include mq(sp) {
    }
  }
  &-contents {
    &-wrapper {
      @include mq(sp) {
        margin-top: 20px;
      }
    }
    &-list {
      padding: 20px 0;
      @include mq(lg) {
        &:not(:last-child) {
          border-bottom: 1px solid black;
        }
      }
      @include mq(pc) {
        &:not(:last-child) {
          border-bottom: 1px solid black;
        }
      }
    }
    &-detail {
      @include mq(lg) {
        display: flex;
      }
      @include mq(pc) {
        display: flex;
      }
      @include mq(sp) {
      }
      &-explain {
        @include textColor(other);
        padding: 0 20px;
        margin: auto;
        @include mq(lg) {
          width: 50%;
        }
        @include mq(pc) {
          width: 50%;
        }
        @include mq(sp) {
          width: 100%;
        }
        &-title {
          @include mq(lg) {
            font-size: 30px;
          }
          @include mq(pc) {
            font-size: 24px;
          }
          @include mq(sp) {
            font-size: 20px;
            padding-bottom: 6px;
            border-bottom: 2px solid #7ea0bb;
          }
        }
        &-text {
          white-space: pre-wrap;
          margin-top: 20px;
          @include mq(lg) {
            font-size: 20px;
          }
          @include mq(pc) {
            font-size: 16px;
          }
          @include mq(sp) {
            font-size: 16px;
          }
        }
      }
      &-image {
        padding: 20px;
        @include mq(lg) {
          width: 50%;
        }
        @include mq(pc) {
          width: 50%;
        }
        @include mq(sp) {
          width: 100%;
        }
        &-content {
          object-fit: cover;
          width: 100%;
          overflow: hidden;
          aspect-ratio: 4/3;
        }
      }
    }
  }
}
// インフォメーション
.top-information {
  margin-top: 100px;
  &-wrapper {
    margin: 30px auto 0;
  }
  &-contents {
    @include textColor(other);
    margin: auto;
    @include mq(lg) {
      width: 60%;
    }
    @include mq(pc) {
      width: 80%;
    }
    @include mq(sp) {
      width: 100%;
    }
    &-list {
      display: flex;
      padding: 20px 0;
      &:not(:last-child) {
        border-bottom: 0.5px solid #3b4043;
      }
      @include mq(lg) {
        font-size: 20px;
      }
      @include mq(pc) {
        font-size: 20px;
      }
      @include mq(sp) {
        font-size: 16px;
      }
      &-title {
        // width: 50%;
        text-align: center;
        margin: auto;
        @include mq(lg) {
          width: 50%;
        }
        @include mq(pc) {
          width: 40%;
        }
        @include mq(sp) {
          width: 40%;
        }
      }
      &-explain {
        @include mq(lg) {
          width: 50%;
        }
        @include mq(pc) {
          width: 60%;
        }
        @include mq(sp) {
          width: 60%;
        }
        &-text {
          white-space: pre-wrap;
        }
      }
    }
  }
  &-access {
    &-map {
      margin: 50px auto 0;
      width: 60%;
      &-content {
        width: 100%;
        aspect-ratio: 16/9;
      }
    }
  }
}

.top-access {
  margin-top: 50px;
  &-contents {
    margin-top: 30px;
    @include mq(lg) {
      display: flex;
    }
    @include mq(pc) {
      display: flex;
    }
    &-explain {
      @include mq(lg) {
        width: 50%;
        padding: 30px 30px 0;
      }
      @include mq(pc) {
        width: 50%;
        padding: 30px 30px 0;
      }
      &-text {
        @include textColor(other);
        font-size: 20px;
        display: flex;
        @include mq(lg) {
          border-bottom: 1px solid;
          font-size: 20px;
        }
        @include mq(pc) {
          border-bottom: 1px solid;
          font-size: 16px;
        }
        @include mq(sp) {
          font-size: 16px;
        }
        &-header {
          margin: auto;
          text-align: center;
          @include mq(lg) {
            width: 20%;
          }
          @include mq(pc) {
            width: 20%;
          }
          @include mq(sp) {
            width: 40%;
          }
        }
        &-inner {
          width: 80%;
          @include mq(lg) {
            width: 80%;
          }
          @include mq(pc) {
            width: 80%;
          }
          @include mq(sp) {
            width: 60%;
          }
        }
      }
      &-image {
        aspect-ratio: 4/3;
        // width: 60%;
        margin-top: 30px;
        @include mq(sp) {
          display: none;
        }
        &-content {
          object-fit: cover;
          // width: 100%;
          height: 100%;
        }
      }
    }
    &-map {
      @include mq(lg) {
        width: 50%;
      }
      @include mq(pc) {
        width: 50%;
      }
      @include mq(sp) {
        margin-top: 20px;
      }
      &-content {
        aspect-ratio: 16/9;
        width: 100%;
        height: 100%;
      }
    }
  }
}
</style>
