<template>
  <header>
    <div class='header-wrapper'>
        <div class="header-icon">
            <a href="/" class="header-icon-image">
                <img src='@/assets/image/logo/logo_alku_type_white.png' class="header-icon-image-contents">
            </a>
        </div>
        <div class="header-menu-pc">
            <li class="header-menu-pc-inner">
                <a href="/#Home"  class="header-menu-pc-inner-link">Home</a>
            </li>
            <li class="header-menu-pc-inner">
                <a href="/#Information" class="header-menu-pc-inner-link">Information</a>
            </li>
            <li class="header-menu-pc-inner">
                <a href="https://alkukukka.stores.jp/" class="header-menu-pc-inner-link">Shopping</a>
            </li>
            <li class="header-menu-pc-inner">
                <a href="/contact" class="header-menu-pc-inner-link">Contact</a>
            </li>
        </div>
        <div class="header-menu-sp" @:click="openSubMenu" :class="{open:showSubMenu}" >
          <p class="header-menu-sp-open">
            menu
          </p>
        </div>
    </div>
    <Transition name="SubMenu">
      <div class="header-sp" v-show="showSubMenu">
        <ul class="header-sp-list">
          <li class="header-sp-list-inner">
              <a href="/#Home"  class="header-sp-list-inner-link">Home</a>
          </li>
          <li class="header-sp-list-inner">
              <a href="/#Information" class="header-sp-list-inner-link">Information</a>
          </li>
          <li class="header-sp-list-inner">
              <a href="https://alkukukka.stores.jp/" class="header-sp-list-inner-link">Shopping</a>
          </li>
          <li class="header-sp-list-inner">
              <a href="/contact" class="header-sp-list-inner-link">Contact</a>
          </li>
        </ul>
      </div>
    </Transition>
  </header>
</template>

<script>
export default {
  data: () => {
    return {
      showSubMenu: false,
    }
  },
  methods: {
    openSubMenu: function () {
      if (!this.showSubMenu) {
        this.showSubMenu = true
      } else {
        this.showSubMenu = false
      }
    }
  }
}
</script>

<style lang="scss">
@import "../assets/css/scss/style.scss";


.header{
  &-wrapper{
    @include jText(n);
    @include fontFamily();
    background-color: #7EA0BB;
    display: flex;
    justify-content: space-between;
    // max-width: $maxWidth;
    margin: auto;
    min-width: $minWidth;
  }
  &-icon{
    padding: 10px 20px;
    @include mq(lg) {
      width: 10%;
    }
    @include mq(pc) {
      width: 20%;
    }
    @include mq(sp) {
      width: 30%;
    }
    &-image-content{
      width: 100%;
    }
  }
}

.header{
  &-menu{
    &-pc{
      padding: 0 50px;
      display: flex;
      justify-content: space-between;
      @include mq(lg) {
        width: 50%;
      }
      @include mq(pc) {
        width: 70%;
      }
      @include mq(sp) {
        display: none;
      }
      &-inner{
        margin: auto 0;
        &:hover{
          border-bottom: 1px solid white;
        }
        &-link{
          color: white;
          transition: 0.5s;
          @include mq(lg) {
            font-size: 24px;
          }
          @include mq(pc) {
            font-size: 20px;
          }
          @include mq(sp) {
            font-size: 24px;
          }
        }
      }
    }
    &-sp{
      @include mq(lg) {
        display: none;
      }
      @include mq(pc) {
        display: none;
      }
      @include mq(sp) {
        // background-color: red;
        display: table;
        padding: 0 20px;
        &-open{
          color: white;
          font-size: 20px;
          vertical-align: middle;
          display: table-cell;
        }
        .open{
          &::before{
            content: "↓";
          }
        }
      }
    }
  }
  &-sp{
    @include mq(lg) {
        display: none;
    }
    @include mq(pc) {
      display: none;
    }
    background-color: #7EA0BB;
    @include fontFamily();
    padding: 20px 0;
    width: 100%;
    min-width: $minWidth;
    @include mq(sp) {
      display: table;
      padding: 20px 10px;
      &-open{
        color: white;
        font-size: 20px;
        vertical-align: middle;
        display: table-cell;
      }
    }
    &-list{
      display: flex;
      justify-content: space-between;
      &-inner{
        color: white;
        &-link{
          color: white;
          transition: 0.5s;
          font-size: 16px;
        }
      }
    }
  }
}

  // .fade-enter-active,
  // .fade-leave-active{
  //   opacity:1s;
  // }
  // .fade-enter,
  // .fade-leave-to{
  //   opacity:0;
  // }

// @keyframes SubMenu {
//   0% {
//     opacity: 0;
//     transform: translateY(-5px);
//   }
//   100% {
//     opacity: 1;
//     transform: translateY(0px);
//   }
// }
.SubMenu-enter-active{
  transition: opacity 1s ease;
}
.SubMenu-leave-active{
  transition: opacity 0.5s ease;
}
.SubMenu-enter-from,
.SubMenu-leave-to {
  opacity: 0;
}
// .SubMenu-leave-active {
//   animation: open .2s linear reverse;
// }
</style>