<template>
  <div id="app">
    <HeaderComponent />
    <router-view />
  </div>
</template>

<script>
import HeaderComponent from './components/HeaderComponent.vue'

export default {
  name: 'App',
  components: {
    HeaderComponent
  }
}
</script>

<style lang="scss">
@import "./assets/css/scss/style.scss";
@import "@/assets/css/reset.css";
@import url('https://fonts.googleapis.com/css2?family=Yantramanav&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Kiwi+Maru&family=Yantramanav&display=swap');
#app {
  @include fontFamily();
  font-family: 'Yantramanav', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
</style>
